import DesktopImg from '@itau-loans-www/shopping/src/assets/images/desktop-aside.png'

import FinalCard from '../FinalCard'
import './qr-code-web.scss'

const WebFlux = ({ title, steps }) => {
  return (
    <div>
      <div className="qr-code-web">
        <div className="qr-code-web__banner">
          <div className="qr-code-web__title-wrapper">
            <h1 className="qr-code-web__title">{title}</h1>
          </div>
        </div>
        <div className="qr-code-web__div-row">
          <div className="qr-code-web__left-wrapper">
            <div className="qr-code-web__img-wrapper">
              <img className="qr-code-web__img" src={DesktopImg} />
            </div>
          </div>
          <div className="qr-code-web__right-wrapper">
            <FinalCard steps={steps} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WebFlux
