import { Header } from '@itau-loans-www/shopping/src/components'

import QrCodeWebFlux from '../../../components/QrCodeWebFlux'
import { steps } from './constants'

const QrCodeFlux = () => {
  return (
    <div className="final_screen">
      <Header />

      <QrCodeWebFlux
        title="Continue a antecipação do Saque-Aniversário, é só baixar o app e continuar de onde parou"
        steps={steps}
      />
    </div>
  )
}

export default QrCodeFlux
