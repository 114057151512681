import { IdsIcon } from '@ids/react'

import './steps-component.scss'

const StepsComponent = ({ stepsList }) => {
  return (
    <div>
      {stepsList.map((steps, index) => {
        return (
          <li className="steps-component__container" key={index}>
            <IdsIcon>{steps.icon}</IdsIcon>
            <span className="steps-component__text">{steps.text}</span>
          </li>
        )
      })}
    </div>
  )
}

export default StepsComponent
