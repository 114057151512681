import { If } from '@itau-loans-www/shopping/src/components'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'

import '../final-screen.scss'
import { useResult } from '../hooks'
import AppFlux from './components/AppFlux'
import QrCodeFlux from './components/QrCodeFlux'

const FgtsWebAppSuccess = () => {
  useResult({})
  const proposalContext = useProposal()
  const qrCode = proposalContext?.product?.proposal?.qrcode ?? ''

  return (
    <If
      condition={!qrCode}
      renderIf={<AppFlux />}
      renderElse={<QrCodeFlux />}
    />
  )
}

export default FgtsWebAppSuccess
