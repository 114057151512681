export const steps = [
  {
    text: 'aponte a câmera do seu celular para o QR code e vamos enviar você para a loja de aplicativos',
    icon: 'download_base'
  },
  {
    text: 'depois de instalar, abra o app e clique em "continuar"',
    icon: 'celular_pre_pago_outline'
  },
  {
    text: 'continue a antecipação do Saque-Aniversário e acompanhe pelo app',
    icon: 'cartao_outline'
  }
]
