import { IdsMainButton } from '@ids/react'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'
import { SafeTag } from '@itau-loans-www/shopping/src/components'
import Layout from '@itau-loans-www/shopping/src/layouts/Summary'
import analytics from '@mobi/libraries/analytics'

import './app-flux.scss'

const AppFlux = () => {
  const proposalContext = useProposal()

  const link = proposalContext?.product?.proposal?.link
  const productCode = proposalContext?.product?.proposal?.productCode

  return (
    <Layout>
      <div className="final_screen">
        <SafeTag />
        <div className="final_screen__content app-flux">
          <h1 className="final_screen__title">
            Já antecipou seu <br /> Saque-Aniversário FGTS?
          </h1>
          <p className="final_screen__text">
            Abra o app Itaú e antecipe até 7 <br /> saques do seu FGTS.
          </p>

          <div className="final_screen__buttons-wrapper">
            <a href={link} target="_blank" rel="noreferrer">
              <IdsMainButton
                full
                id="fgts-web-app-fallback"
                onClick={() => {
                  analytics.track('buttonClick', {
                    currentPage: 'telas-finais-de-redirect-do-produto',
                    detail: productCode
                  })
                }}
              >
                Abrir app Itaú
              </IdsMainButton>
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AppFlux
