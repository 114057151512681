import AppleImg from '@itau-loans-www/shopping/src/assets/images/apple-store.png'
import GoogleImg from '@itau-loans-www/shopping/src/assets/images/google-play.png'
import { SeeOffersButton } from '@itau-loans-www/shopping/src/components'
import { useProposal } from '@itau-loans-www/shopping/src/hooks'

import StepsComponent from '../StepsComponent'
import './final-card.scss'

const FinalCard = ({ steps }) => {
  const proposalContext = useProposal()
  const qrCode = proposalContext?.product?.proposal?.qrcode ?? ''

  return (
    <div className="final-card">
      <span className="final-card__title">Próximos passos</span>
      <StepsComponent stepsList={steps} />
      <div className="final-card__qrCode-wrapper">
        <img
          src={`data:image/jpeg;base64,${qrCode}`}
          alt="qrcode para abrir aplicativo"
          className="final-card__qrCode-img"
        />

        <div className="final-card__app-wrapper">
          <span className="final-card__qrCode-text">baixe o app Itaú</span>
          <span className="final-card__store-text">disponível nas lojas</span>
          <div className="final-card__logo-wrapper">
            <img src={GoogleImg} width="120" height="25" alt="Google play" />
            <img src={AppleImg} width="120" height="29" alt="Apple Store" />
          </div>
        </div>
      </div>

      <SeeOffersButton isCrossSell />
    </div>
  )
}

export default FinalCard
